@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fade-out {
    animation: fadeOut 0.5s ease-in-out;
    opacity: 0;
    z-index: -999999999;
}
